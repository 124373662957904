var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bpmnjs-space bpmnjs-document-viewer"},[_c('div',{domProps:{"innerHTML":_vm._s(`
    <style>
      .bpmnjs-document-viewer .bjs-breadcrumbs {
        left: 70px !important;
        top: 34px !important;
      }    
    </style>
  `)}}),_c('div',{ref:"container",staticClass:"bpmnjs-viewer",style:({ height: _vm.height})}),(_vm.putContent)?_c('v-toolbar',{staticClass:"toolbar",attrs:{"dense":"","floating":"","elevation":"0","color":"rgba(0, 0, 0, 0.03)"}},[_c('v-btn',{attrs:{"icon":"","title":"Редактировать"},on:{"click":_vm.onEdit}},[_c('v-icon',[_vm._v("mdi-file-edit-outline")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }