var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bpmnjs-document-modeler"},[_c('div',{domProps:{"innerHTML":_vm._s(`
    <style>
      .bpmnjs-document-modeler .bjs-breadcrumbs {
        left: 180px !important;
        top: 34px !important;
      }    
    </style>
  `)}}),_c('div',{staticClass:"desk",attrs:{"id":_vm.descId}}),_c('v-toolbar',{staticClass:"toolbar",attrs:{"dense":"","floating":"","elevation":"0","color":"rgba(0, 0, 0, 0.03)"}},[(_vm.putContent)?_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.save}},[_c('v-icon',[_vm._v("mdi-content-save")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.onExit(false)}}},[_c('v-icon',[_vm._v("mdi-exit-run")])],1)],1),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.saveRequest),callback:function ($$v) {_vm.saveRequest=$$v},expression:"saveRequest"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Сохранить? ")]),_c('v-card-text',[_vm._v(" В диаграмму внесены изменения. Если их не сохранить, то они будут утеряны. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.saveRequest = false; _vm.onExit(true)}}},[_vm._v(" Не сохранять ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.onExit(true)}}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }